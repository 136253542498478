import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
import bg_pic from '../Media/Images/AIV03234.jpg';


const Layout = ({ children }) => {
    return (
        <div className="flex flex-col h-screen min-w-430 min-h-900">
            <div className="sticky top-0 z-50">
                <Header />            
            </div>

            <div className="h-full bg-no-repeat bg-cover overflow-y-auto"
                style={{ backgroundImage: `url(${bg_pic})` }}
            >
                <div className="flex-1 h-full">
                    {children}
                </div>
            </div>

                <Footer />
        </div>
    );
};

export default Layout;
