import { useContext } from 'react';
import Layout from '../components/Layout';
import bg_pic from '../Media/Images/AIV03508.jpg';
import BookForm from '../components/BookForm';
import HuntsContext from '../Context/HuntsContext';
import BookingsCalendar from '../components/FullCalendar';

const BookHuntPage = () => {
    const { hunts } = useContext(HuntsContext);


    return (
        <Layout>
            <div
                className="w-full h-fit p-[2rem] bg-no-repeat bg-cover text-PHEASANT_RED font-serif flex justify-center"
                style={{ backgroundImage: `url(${bg_pic})` }}
            >
                <div className="w-full h-fit bg-orange-50 border-4 border-PHEASANT_RED rounded-[50px] 
                                p-6 flex flex-col gap-6 justify-end items-center">

                    <div className="h-[1050px] w-full text-xl font-semibold bg-[#FFFFFF] p-4 
                                    border-2 border-PHEASANT_RED rounded-[25px]">
                        <BookingsCalendar hunts={hunts} />
                    </div>

                    <div className="w-[600px] h-full border-2 bg-white border-PHEASANT_RED rounded-[25px] mr-6">
                        <BookForm />
                    </div>

                </div>
            </div>
        </Layout>
    );
};
export default BookHuntPage
