import React from 'react'
import FullCalendar from '@fullcalendar/react'

//  FullCalendar Plug-ins
import dayGridPlugin from '@fullcalendar/daygrid'
import multiMonthPlugin from '@fullcalendar/multimonth'
import { DayHeader } from '@fullcalendar/core/internal'

const BookingsCalendar = ( {hunts} ) => {
    const seasonView = {
        type: 'multiMonth',
        buttonText: 'season',
        visibleRange: {
            start: '2024-10-01',
            end: '2025-03-31'
        },
        buttonText: 'Season',
        titleFormat: {year: '2-digit', month: 'short'},
        dayHeaderFormat: {weekday: 'narrow'}
    };

    return (
        <FullCalendar

            plugins={[
                dayGridPlugin,
                multiMonthPlugin 
            ]}

            headerToolbar={{
                left: 'prev next today',
                center: 'title',
                right: 'seasonView dayGridMonth'
            }}

            initialView='seasonView' 
            views={{seasonView}}
            events={hunts}
            height='100%'
            multiMonthMinWidth='250'

        />
    )
}

export default BookingsCalendar
