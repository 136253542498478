import React from 'react';
import header_sm from '../Media/Images/header2.jpg';
import header_md from '../Media/Images/header3.jpg';
import header_lg from '../Media/Images/header4.jpg';
import header_xl from '../Media/Images/header5.jpg';
import logo from '../Media/Images/Logo.png';
import Navbar from '../components/Navbar';

const Header = () => {
    return (
        <header className="relative">

            <div className="h-[150px] md:h-[200px] lg:h-[250px]">
                <picture >
                    <source srcset={header_xl} media="(min-width: 1500px)"/>
                    <source srcset={header_lg} media="(min-width: 1000px)"/>
                    <source srcset={header_md} media="(min-width: 600px)"/>
                    <img src={header_sm} alt="pheasant header" className="w-screen h-full"/>
                </picture>

                <img src={logo} alt="logo" className="absolute top-0 left-0 right-0 w-[200px] h-[125px] md:w-[250px] md:h-[175px] lg:w-[325px] lg:h-[225px] mx-auto z-50"/> 
            </div>

            <div className="flex flex-col md:flex-row h-auto w-screen bg-PHEASANT_RED">
                <Navbar />
            </div>


        </header>
    );
};

export default Header