import React from 'react'
import { Link } from 'react-router-dom';
import { FaFacebook, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="h-30 bg-PHEASANT_RED text-gray-200 grid grid-cols-2 gap-8">
        <div className="flex flex-col items-center justify-center">
            <div className="text-right text-md pr-4 border-r-2 border-gray-400">
                <p className="hidden md:block md:text-lg mt-1">BEAVER CREEK UPLAND HUNTS</p>
                <p className="hidden md:block md:text-sm">15360 Road 3</p>
                <p className="hidden md:block md:text-sm">McDonald, KS 67745</p>
                <p className="mt-4 mb-2 text-xs">© 2024 Beaver Creek Upland Hunts </p>
            </div>
        </div>

        <div className="flex flex-col items-center justify-center mb-2 mr-2 text-center">
            <p className="hidden md:block">Follow us on Social Media</p>
            
            <div className="flex flex-row gap-8 mt-2">
                {/* FACEBOOK */}
                <div className="w-10 h-10 rounded-full mt-2 bg-gray-100 flex items-center justify-center">
                    <Link 
                        to={"https://www.facebook.com/search/top?q=beaver%20creek%20upland%20hunts"} 
                        target= "_blank" 
                        className="text-5xl text-blue-900">
                        <FaFacebook />
                    </Link>
                </div>

                {/* INSTAGRAM /> */}
                <div className="w-10 h-10 rounded-xl mt-2 bg-gradient-to-b from-purple-500 to-pink-500 flex items-center justify-center border-1 border">
                    <Link 
                        to={"https://www.instagram.com/beavercreekuplandhunts?igsh=MXZ4MzZwNmNpczBlbw%3D%3D&utm_source=qr"} 
                        target= "_blank" 
                        className="text-4xl text-white">
                        <FaInstagram />
                    </Link>
                </div>
            </div>

        </div>

    </div>
  )
}

export default Footer