import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import bg_pic from '../Media/Images/AIV03234.jpg';

const FAQPage = () => {
    const navigate = useNavigate();

    const question = "py-4 px-8 my-4 mx-auto border-2-[PHEASANT_RED] rounded-[40px] bg-white/75 text-black text-[1.5rem] font-['Times'] font-bold md:w-[70%]";
    const answer = "pl-5 italic text-[PHEASANT_RED] text-[1rem] leading-tight";
    const bulletedList = "list-disc pl-4";
    const listItem = "pl-3 mb-2"

    return (
        <Layout>
            <div
                className="py-6 h-fit bg-no-repeat bg-cover text-PHEASANT_RED font-serif px-3"
                style={{ backgroundImage: `url(${bg_pic})` }}
            >
                {/* Content here if needed */}

                <div className="flex justify-center text-center w-full font-['Times'] font-bold text-[4rem] italic mt-[4rem] mb-[6rem] leading-[3.25rem]">
                    Frequently Asked Questions
                </div>

                <ul className="mb-14">
                    <li>
                        <div className={question}>
                            Can I bring my own dog?
                            <div className={answer}>
                                Yes you can!  Please see our hunt packages for more information on pricing variations.
                                <ul className={bulletedList}> 
                                    <li className={listItem}>Owners must bring enclosed portable kennels or crates for overnight stays</li>
                                    <li className={listItem}>Dogs must not be aggressive</li>
                                    <li className={listItem}>Dogs must be used to living in houses (no barking or biting)</li>
                                    <li className={listItem}>Dogs must be vaccinated</li>
                                </ul>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className={question}>
                            What do I need to hunt?
                            <div className={answer}>
                                All hunters are required to have the following:
                                <ul className={bulletedList}> 
                                    <li className={listItem}>A blaze-orange vest and hat on while hunting</li>
                                    <li className={listItem}>A Kansas Hunting Licence</li>
                                    <li className={listItem}>A Controlled Shooting Licence</li>
                                </ul>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className={question}>
                            What should I wear?
                            <div className={answer}>
                                <ul className={bulletedList}> 
                                    <li className={listItem}>We recommend you wear long pants that you feel comfortable 
                                                         walking through brush with (jeans, brush pants, chaps, etc.)</li>
                                    <li className={listItem}>Pay attention to the weather:  we operate in an area where forecasts 
                                                         can change very quickly.  Pack what you might need for the worst case scenario 
                                                         (even though you may not need it).</li>
                                    <li className={listItem}>Wear boots that you are comfortable walking some distance in.</li>
                                </ul>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className={question}>
                            What should I bring for ammo?
                            <div className={answer}>
                                <ul className={bulletedList}> 
                                    <li className={listItem}>We recommend shooting 5 or 6 shot for a pheasant hunt</li>
                                    <li className={listItem}>Bring more than you think you will need.  If you run out, 
                                                         additional ammo is available for sale so you can keep hunting.</li>
                                </ul>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div className={question}>
                            Got more questions?
                            <div className={answer}>
                                Reach out to Caleb at<br></br>CalebFisher2518@gmail.com<br></br>or 262-957-0454
                            </div>
                        </div>
                    </li>

                </ul>

            </div> 

    </Layout>
  );
};

export default FAQPage
