import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faShoppingCart } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const navList = (
    <ul className="w-full flex flex-col md:flex-row text-orange-200 font-semibold text-2xl text-center justify-center">
      <li className="w-full border-b-[1px] border-orange-100/50 md:border-none">
        <Link
          to={"/"}
          className="hover:text-gray-100 transition-colors duration-200"
        >
          Home
        </Link>
      </li>

      {/* <li>
        <Link
          to={"/book"}
          className="hover:text-gray-100 transition-colors duration-200"
        >
          Book Hunt
        </Link>
      </li> */}

      <li className="w-full border-b-[1px] border-orange-100/50 md:border-none">
        <Link
          to={"/FAQ"}
          className="hover:text-gray-100 transition-colors duration-200"
        >
          FAQ
        </Link>
      </li>

      <li className="w-full">
        <Link
          to={"/Packages"}
          className="hover:text-gray-100 transition-colors duration-200"
        >
          Packages
        </Link>
      </li>

    </ul>
  );

  return (
    <nav>
      <div className="w-screen mx-auto px-4 flex md:justify-between md:items-center py-2">
        <div className="w-full flex items-center md:space-x-8 text-2xl">
          {navList}

          {/* <Link to={"/login"} className="text-orange-200 hover:text-gray-100">
            <FontAwesomeIcon icon={faUser} />
          </Link> */}

          {/* <Link to={"/cart"} className="text-orange-200 hover:text-gray-100 ">
            <FontAwesomeIcon icon={faShoppingCart} />
          </Link> */}

        </div>
      </div>
    </nav>
  );
};

export default Navbar;
